/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { Button } from "antd";
import {
  User,
  UserManager,
  UserManagerSettings,
  UserSettings,
} from "oidc-client";
import { authenticateUser } from "@axa-fr/react-oidc-context";
import { AuthService, Login } from "../../services/auth";

import "./login.scss";
import { AnalyticsOidcConfiguration } from "../../oidc-configuration";
import Routes from "../../page-routes";
import { withRouter } from "react-router-dom";
import { useUsers } from "../../hooks";
import {
  CognitoIdentity,
  ILoginIdentityResponse,
  AuthChallenge
} from "../../services/auth/auth-service";

const emailRegex = "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$";

const LoginRoute = ({ history }: any) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [usePassword, setUsePassword] = useState(false);
  const [loginButtonEnabled, setLoginButtonEnabled] = useState(false);
  const [refreshingToken, setRefreshingToken] = useState(false);

  const user = useUsers();
  useEffect(() => {
    if (user) {
      setRefreshingToken(true);
      const m = new UserManager(AnalyticsOidcConfiguration);

      m.signinSilent()
        .then(() => {
          history.push(Routes.Analytics);
        })
        .catch(() => {
          setRefreshingToken(false);
        });
    }
  }, [user]);

  const handleEmailChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(target.value);

    const regex = new RegExp(emailRegex);

    setLoginButtonEnabled(regex.test(target.value));
  };

  const handlePasswordChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(target.value);

    setLoginButtonEnabled(target.value.length > 0);
  };

  const handleForgotPassword = () => {
    AuthService.forgotPassword(email).then((data: any) => {
      if (data=== 'success') {
      history.push({
        pathname: '/reset-password',
        state: { email, resetFlow: true }
    })
  } else {
    setErrorMessage(data.message);
  }
  }).catch((e: any) => console.log(e))
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage("");

    if (!email) {
      setErrorMessage("An email is required!");
      return;
    }

    if (usePassword && !password) {
      setErrorMessage("A password is required!");
      return;
    }

    setLoading(true);
    setErrorMessage("");

    const login: Login = {
      email,
      password,
    };

    const response = await AuthService.login(login);

    if (response instanceof Error) {
      setErrorMessage(response.message);

      setLoading(false);
      return;
    }


    if ((response as AuthChallenge).challenge == "NEW_PASSWORD_REQUIRED") {
      history.push({
        pathname: Routes.ResetPassword,
        state: login
      })
    }

    if (["SMS_MFA", "EMAIL_OTP"].includes((response as AuthChallenge).challenge)) {
      history.push({
        pathname: Routes.MFAChallenge,
        state: {email, session: (response as AuthChallenge).session, challenge: (response as AuthChallenge).challenge, userId: (response as AuthChallenge).userId}
      })  
    }

    if ((response as UserSettings).access_token) {
      handleSuccessUserLogin(response as UserSettings);

      return;
    }

    const { identity } = response as ILoginIdentityResponse;

    if (identity && identity !== CognitoIdentity) {
      const updatedOidcDetails: UserManagerSettings = {
        ...AnalyticsOidcConfiguration,
        extraQueryParams: {
          identity_provider: identity,
        },
      };

      const m = new UserManager(updatedOidcDetails);

      await authenticateUser(m, window.location)(false, Routes.Analytics);
      return;
    }

    setLoading(false);
    setUsePassword(true);
  };

  const handleSuccessUserLogin = async (userSettings: UserSettings) => {
    const m = new UserManager(AnalyticsOidcConfiguration);

    await m.storeUser(new User(userSettings)).then(() => {
      window.location.href = Routes.Analytics;
    });
  };

  const getButtonText = () => {
    if (loading) {
      if (usePassword) return "Logging in...";
      else return "Attempting to find identity...";
    }

    if (!usePassword) return "Next";
    else return "Login";
  };

  return (
    <div className="d-flex align-items-center justify-content-center max-height">
      <div className="wrapper shadow rounded d-flex justify-content-between flex-column">
        {refreshingToken && (
          <h4 className="ant-typography text-center">
            Retrieving login information...
          </h4>
        )}
        {!refreshingToken && (
          <>
            <div>
              <h4 className="ant-typography" style={{ paddingBottom: 14 }}>
                Login
              </h4>
            </div>
            <div className="mt-2 w-100">
              <form onSubmit={handleSubmit}>
                <div className="ant-form-item px-0 w-100">
                  <label>Email</label>
                  <input
                    placeholder="johnsmith@email.com"
                    disabled={loading}
                    id="username"
                    onChange={handleEmailChange}
                    className="ant-input"
                  />
                </div>

                {usePassword && (
                  <div className="ant-form-item w-100">
                    <label>Password</label>
                    <input
                      placeholder="*******"
                      id="password"
                      disabled={loading}
                      value={password}
                      onChange={handlePasswordChange}
                      className="ant-input"
                      type="password"
                      autoFocus={true}
                    />
                        <Button  onClick={handleForgotPassword} style={{paddingLeft: '0' }} type="link" disabled={loading || !loginButtonEnabled}>
                          forgot password
                        </Button>
                  </div>
                )}

                {errorMessage && <p className="text-danger">{errorMessage}</p>}
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  {getButtonText() =='Login'
                    ? (<a href="/login">
                      <Button
                      >
                        Cancel
                      </Button>
                    </a>) : <div/>
                  }
                  <Button
                    disabled={loading || !loginButtonEnabled}
                    type="primary"
                    htmlType="submit"
                    loading={getButtonText()==='Attempting to find identity...'}
                  >
                    {getButtonText()==='Attempting to find identity...' ? 'Next': getButtonText() /* Just a quick and dirty way to get the button to display Next with a loader */}
                  </Button>
                </div>

              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(LoginRoute);
