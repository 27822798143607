import AWS from "aws-sdk";
import { User, UserManager, UserSettings } from "oidc-client";
import { decode } from "jsonwebtoken";
import { AnalyticsOidcConfiguration } from "./../../oidc-configuration";
import { Login } from "../../routes";

const getUserManager = () => analyticsManager;

const CLIENT_ID = process.env.REACT_APP_OAUTH_ANALYTICS_CLIENT_ID as string;

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
});

const analyticsManager = new UserManager(AnalyticsOidcConfiguration);
export type AuthContext = "analytics";

export type AuthChallengeType =
  | "SMS_MFA"
  | "EMAIL_OTP"
  | "SOFTWARE_TOKEN_MFA"
  | "SELECT_MFA_TYPE"
  | "MFA_SETUP"
  | "PASSWORD_VERIFIER"
  | "CUSTOM_CHALLENGE"
  | "DEVICE_SRP_AUTH"
  | "DEVICE_PASSWORD_VERIFIER"
  | "ADMIN_NO_SRP_AUTH"
  | "NEW_PASSWORD_REQUIRED";

export enum LoginErrorMessage {
  InvalidCombination = "Invalid Email/Password combination",
  NoAccessToken = "No access token was returned in the result",
  UnknownError = "An unknown error has occurred",
  NewPasswordRequired = "New Password Required",
  ResetError = "Please ensure email is correct and account confirmed",
}

export interface Login {
  email: string;
  password: string;
}

export interface CodeDeliveryDetails {
  AttributeName: string;
  DeliveryMedium: string;
  Destination: string;
}

export interface AuthChallenge {
  challenge: AuthChallengeType;
  session: string;
  userId: string;
}

export interface ChangePassword {
  email: string;
  oldPassword: string;
  newPassword: string;
}

export interface SubmitMFA {
  email: string;
  code: string;
  session: string;
  type: string;
  userId: string;
}

export interface ChangePasswordWithCode {
  email: string;
  code: string;
  newPassword: string;
}

export const CognitoIdentity = "Cognito";

export interface ILoginIdentityResponse {
  identity: string;
}

interface AuthService {
  changePassword: (
    changePassword: ChangePassword
  ) => Promise<UserSettings | Error | AuthChallenge>;
  submitMFA: (submitMFA: SubmitMFA) => Promise<UserSettings | Error>;
  resendMFACode: (userId: string) => Promise<CodeDeliveryDetails | Error>;
  forgotPassword: (username: string) => Promise<"success" | Error>;
  getUser: () => Promise<User | null>;
  getUserManager: () => UserManager;
  login: (
    login: Login
  ) => Promise<ILoginIdentityResponse | UserSettings | Error | AuthChallenge>;
  setNewPasswordWithCode: (
    changePassword: ChangePasswordWithCode
  ) => Promise<ILoginIdentityResponse | UserSettings | Error | AuthChallenge>;
}

export const AuthService: AuthService = {
  getUser: () => getUserManager().getUser(),
  getUserManager: () => getUserManager(),
  login: async (login: Login) => {
    if (login.password) {
      return passwordLogin(login);
    }

    const { email } = login;

    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/login/identity`,
      { method: "POST", body: JSON.stringify({ email }) }
    )
      .then((x) => x.json())
      .then((x) => x as ILoginIdentityResponse);

    return response;
  },

  changePassword: async ({
    email,
    oldPassword,
    newPassword,
  }: ChangePassword): Promise<UserSettings | Error | AuthChallenge> => {
    const cog = new AWS.CognitoIdentityServiceProvider();
    const user = await cog
      .initiateAuth({
        AuthFlow: "USER_PASSWORD_AUTH",
        ClientId: CLIENT_ID,
        AuthParameters: {
          USERNAME: email,
          PASSWORD: oldPassword,
        },
      })
      .promise()
      .then(
        async ({
          AuthenticationResult,
          ChallengeName,
          Session,
          ChallengeParameters,
        }) => {
          if (ChallengeName == "NEW_PASSWORD_REQUIRED") {
            const newUser = await cog
              .respondToAuthChallenge({
                ChallengeName: "NEW_PASSWORD_REQUIRED",
                ChallengeResponses: {
                  USERNAME: email,
                  NEW_PASSWORD: newPassword,
                },
                ClientId: CLIENT_ID,
                Session,
              })
              .promise();

            if (
              ["SMS_MFA", "EMAIL_OTP"].includes(
                newUser.ChallengeName as AuthChallengeType
              )
            ) {
              return {
                challenge: newUser.ChallengeName as AuthChallengeType,
                session: newUser.Session,
                userId: ChallengeParameters?.USER_ID_FOR_SRP,
              } as AuthChallenge;
            }

            if (!newUser.AuthenticationResult) {
              return Error(LoginErrorMessage.UnknownError);
            }
            if (!newUser.AuthenticationResult.AccessToken) {
              return Error(LoginErrorMessage.NoAccessToken);
            }

            const profile = decode(newUser.AuthenticationResult.AccessToken);

            return {
              access_token: newUser.AuthenticationResult.AccessToken,
              expires_at:
                Date.now() + (newUser.AuthenticationResult.ExpiresIn || 0),
              id_token: newUser.AuthenticationResult.IdToken,
              refresh_token: newUser.AuthenticationResult.RefreshToken,
              token_type: newUser.AuthenticationResult.TokenType,
              profile,
            } as UserSettings;
          }
          if (
            ["SMS_MFA", "EMAIL_OTP"].includes(
              ChallengeName as AuthChallengeType
            )
          ) {
            return {
              challenge: ChallengeName as AuthChallengeType,
              session: Session,
              userId: ChallengeParameters?.USER_ID_FOR_SRP,
            } as AuthChallenge;
          }

          if (!AuthenticationResult) {
            return Error(LoginErrorMessage.UnknownError);
          }
        }
      )
      .catch((error) => {
        return Error(error);
      });
    if (!user) {
      return Error(LoginErrorMessage.UnknownError);
    }
    return user;
  },

  submitMFA: async ({
    email,
    code,
    session,
    type,
    userId,
  }: SubmitMFA): Promise<UserSettings | Error> => {
    const cog = new AWS.CognitoIdentityServiceProvider();
    const user = await cog
      .respondToAuthChallenge({
        ChallengeName: type,
        ChallengeResponses: {
          USERNAME: userId,
          [`${type}_CODE`]: code,
        },
        ClientId: CLIENT_ID,
        Session: session,
      })
      .promise()
      .then((user) => {
        if (!user.AuthenticationResult) {
          return Error(LoginErrorMessage.UnknownError);
        }
        if (!user.AuthenticationResult.AccessToken) {
          return Error(LoginErrorMessage.NoAccessToken);
        }

        const profile = decode(user.AuthenticationResult.AccessToken);

        return {
          access_token: user.AuthenticationResult.AccessToken,
          expires_at: Date.now() + (user.AuthenticationResult.ExpiresIn || 0),
          id_token: user.AuthenticationResult.IdToken,
          refresh_token: user.AuthenticationResult.RefreshToken,
          token_type: user.AuthenticationResult.TokenType,
          profile,
        } as UserSettings;
      })
      .catch((error) => {
        return Error(error);
      });
    return user;
  },

  resendMFACode: async (userId): Promise<CodeDeliveryDetails | Error> => {
    const cog = new AWS.CognitoIdentityServiceProvider();
    const resetResult = await cog
      .resendConfirmationCode({
        Username: userId,
        ClientId: CLIENT_ID,
      })
      .promise()
      .then((resetResult) => {
        return {
          AttributeName: resetResult.CodeDeliveryDetails?.AttributeName,
          DeliveryMedium: resetResult.CodeDeliveryDetails?.DeliveryMedium,
          Destination: resetResult.CodeDeliveryDetails?.Destination,
        } as CodeDeliveryDetails;
      })
      .catch((error) => {
        return Error(error);
      });
    return resetResult;
  },

  forgotPassword: async (username: string) => {
    // Just triggers Cognito to send a code to the users tmail
    const params = {
      ClientId: CLIENT_ID,
      Username: username,
    };

    const cog = new AWS.CognitoIdentityServiceProvider();
    try {
      return await cog
        .forgotPassword(params)
        .promise()
        .then(() => "success");
    } catch (error) {
      return Error(LoginErrorMessage.ResetError);
    }
  },

  setNewPasswordWithCode: async ({
    // This will only work for users who have signed into the app previously to confirm their account
    email,
    code,
    newPassword,
  }: ChangePasswordWithCode): Promise<any> => {
    const cog = new AWS.CognitoIdentityServiceProvider();
    return await cog
      .confirmForgotPassword({
        ConfirmationCode: code,
        ClientId: CLIENT_ID,
        Username: email,
        Password: newPassword,
      })
      .promise()
      .then(async () => await passwordLogin({ email, password: newPassword }));
  },
};

const passwordLogin = async ({
  email,
  password,
}: Login): Promise<Error | UserSettings | AuthChallenge> => {
  const cog = new AWS.CognitoIdentityServiceProvider();

  const user = await cog
    .initiateAuth({
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: CLIENT_ID,
      AuthParameters: {
        USERNAME: email,
        PASSWORD: password,
      },
    })
    .promise()
    .then(
      ({
        AuthenticationResult,
        ChallengeName,
        Session,
        ChallengeParameters,
      }) => {
        if (ChallengeName) {
          return {
            challenge: ChallengeName as AuthChallengeType,
            session: Session,
            userId: ChallengeParameters?.USER_ID_FOR_SRP,
          } as AuthChallenge;
        }
        if (!AuthenticationResult) {
          return Error(LoginErrorMessage.UnknownError);
        }

        if (!AuthenticationResult.AccessToken) {
          return Error(LoginErrorMessage.NoAccessToken);
        }

        const profile = decode(AuthenticationResult.AccessToken);

        return {
          access_token: AuthenticationResult.AccessToken,
          expires_at: Date.now() + (AuthenticationResult.ExpiresIn || 0),
          id_token: AuthenticationResult.IdToken,
          refresh_token: AuthenticationResult.RefreshToken,
          token_type: AuthenticationResult.TokenType,
          profile,
        } as UserSettings;
      }
    )
    .catch((error) => {
      return Error(error);
    });

  return user;
};
